import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Box, Heading, Space } from "../components/ui"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

interface Picture {
  id: string
  alt: string
  gatsbyImageData: IGatsbyImageData
  url: string
}

interface PageProps {
  data: {
    page: {
      id: string
      title: string
      slug: string
      description: string
      image: {
        id: string
        url: string
        alt: string
        gatsbyImageData: IGatsbyImageData
      }
      html: string
    }
    contentfulPage: {
      roomPictures: Picture[]
    }
  }
}

export default function Page(props: PageProps) {
  const { page, contentfulPage } = props.data
  const { roomPictures } = contentfulPage

  return (
    <Layout {...page}>
      <Box paddingY={5}>
        <Container width="narrow">
          <Heading as="h1">{page.title}</Heading>
          {roomPictures &&
            roomPictures.map((image) => (
              <>
                <Space size={4} />
                <GatsbyImage
                  key={image.id}
                  alt={image.alt}
                  image={image.gatsbyImageData}
                />
              </>
            ))}
          <div
            dangerouslySetInnerHTML={{
              __html: page.html,
            }}
          />
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query PageContent($id: String!) {
    page(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        url
        alt
        gatsbyImageData
      }
      html
    }
    contentfulPage(id: { eq: $id }) {
      roomPictures {
        id
        alt
        gatsbyImageData
        url
      }
    }
  }
`
